import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AccessService {
    endpoint = environment.URL_GATEWAY + '/api/access';

    constructor(private http: HttpClient) {
    }

    ativar(token: string): Observable<any> {
        return this.http.get<Observable<any>>(
            `${this.endpoint}/ativar?token=${token}`
        );
    }

    forgot(cpf: string): Observable<any> {
        return this.http.post<Observable<any>>(
            `${this.endpoint}?cpf=${cpf}`,
            null,
            {
                observe: 'response'
            }
        );
    }

    newPassword(token: string, login: string, senha: string): Observable<any> {
        return this.http.put<Observable<any>>(`${this.endpoint}`, {
            resetToken: token,
            login: login,
            senha: senha
        });
    }

    cadastrarEmailPrincipal(cpf: string, email: string): Observable<any> {
        return this.http.post<Observable<any>>(
            `${this.endpoint}/cadastrar-email-principal/enviar-email-senha?cpf=${cpf}&email=${email}`,
            null,
            {
                observe: 'response'
            }
        );
    }
}
